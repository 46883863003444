export const ROUTE = {
  PAYMENT: '/payment',
  COMPLETE_ORDER: '/customer-receipt',
  TAB: '/tab',
  HOME: '/menus',
  UNSUPPORTED_DEVICE: '/unsupported-device',
};

export const ALLOWED_BYPASS_PATH_ROUTE_NAMES = [
  '/feedback',
  '/report-error',
  '/payments/receipt',
  '/closed',
];
