export const LOCAL_STORAGE_KEY = {
  MY_ID: 'myId',
  CURRENT_TOKEN: 'currentToken',
  MY_CURRENT_ORDER_ID: 'myCurrentOrderId',
  MOVE_TO_PG_SIDE: 'moveToPGSide',
  FINGERPRINT: 'fingerPrint',
  FINGERPRINT_REQUEST_ID: 'fingerprintRequestId',
  SID: 'sid',
  GUEST_ACCESS_TOKEN: 'guestAccessToken',
  LINKED_WITH_FINGERPRINT: 'customerLinkedWithFingerPrint',
  ALREADY_CLOSED: 'alreadyClosed',
};
